import React, { memo } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, TextField, Zoom, MenuItem, Button, ButtonGroup, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import vPack from './pack.json'
import AOS from "aos";
import Skeleton from '@material-ui/lab/Skeleton';
import usePagination  from '../pagination';
import { Pagination } from '@material-ui/lab';

const Memberlist = ({ fet, setSec, width }) => {

    React.useEffect(() => {
        setSec('Members')
    }, [])


    const History = useHistory()

    const [seGroup, setGr] = React.useState('-');
    const [seFill, setFr] = React.useState('-');
    const [seGrad, setGrad] = React.useState('-');
    const [search, setSearch] = React.useState('');
    const [Loaded, setLoaded] = React.useState(false);
    const [Filter, setFilter] = React.useState([]);


    const [Arr, setArr] = React.useState([]);
    const [mem, setmem] = React.useState([]);

    const [pageset, setPagin] = React.useState(1);
    const PER_PAGE = 8;

    let count = Math.ceil(mem.length / PER_PAGE);
    let _DATA = usePagination(mem, PER_PAGE);

    const handleChange = (e, p) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        setPagin(p);
        _DATA.jump(p);
    };

    React.useEffect(() => {
        AOS.init({ duration: 1000 });
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        var url = new URL(window.location.href);
        if (url.searchParams.get("filter") != null && url.searchParams.get("val") != null) {
            setFilter(vPack[url.searchParams.get("filter")])
            setGr(url.searchParams.get("filter"));
            setFr(url.searchParams.get("val"))
            setLoaded(false)
            fetch(fet + '/bnk48/getmemberby?filter=' + url.searchParams.get("filter") + '&param=' + url.searchParams.get("val") + '&tstamp=' + Math.floor(new Date().getTime() / 1000), {
                method: 'post'
            })
                .then(response => response.json())
                .then(async data => {
                    setArr(data.response)
                    if (search !== '') {
                        const txt = search.toLowerCase()
                        setSearch(txt)
                        const d = data.response.filter(x => (x.name.toLowerCase()).includes(txt));
                        setmem(d)
                    } else {
                        setmem(data.response)
                        setArr(data.response)
                    }
                    setLoaded(true)
                });
        } else {
            fetch(fet + '/bnk48/memberlist?tstamp=' + Math.floor(new Date().getTime() / 1000), {
                method: 'get'
            })
                .then(response => response.json())
                .then(data => {
                    setmem(data.response)
                    setArr(data.response)
                    setLoaded(true)
                }).catch(() => {
                    setmem([])
                    setArr([])
                    setLoaded(true)
                })
        }

    }, [])


    const ChangeRoute = (name) => {
        History.push("/member/" + name.toLowerCase())
    }

    const SearchEng = (val) => {
        const txt = val.toLowerCase()
        setSearch(txt)
        if (txt == '') {
            setmem(Arr)
        } else {
            const data = Arr.filter(x => (x.name.toLowerCase()).includes(txt));
            setmem(data)
        }
        if (pageset > 1) {
            setPagin(1)
            _DATA.jump(1);
           }
    }

    const handleChangeGroup = (event) => {
        setFr('-')
        setGr(event.target.value);
        if (event.target.value == 'team') {
            setFilter(vPack.team)
        } else if (event.target.value == 'gen') {
            setFilter(vPack.gen)
        } else {
            setFilter([])
        }
        if (pageset > 1) {
            setPagin(1)
            _DATA.jump(1);
           }
    };

    const onSearch = () => {
        if (Arr.length > 0 && mem.length > 0) {
            let newfilter = [];
            if (seGroup != '-' && seFill != "-") {

                if (seGroup == "team") {
                    newfilter = Arr.filter(x => x.team.filter(teamx => teamx == seFill).length > 0)
                } else if (seGroup == "gen") {
                    newfilter = Arr.filter(x => x.gen == seFill)
                }

                if (search !== '') {
                    const txt = search.toLowerCase()
                    setSearch(txt)
                    const d = newfilter.filter(x => (x.name.toLowerCase()).includes(txt));
                    setmem(d)
                } else {
                    setmem(newfilter)
                }
            }
            if (pageset > 1) {
                setPagin(1)
                _DATA.jump(1);
               }
        }
    }

    const onReset = () => {
        if (seGroup != '-' || seFill != "-" || search != '') {
            setmem(Arr)
            setFilter([])
            setGr('-')
            setFr('-')
            setSearch('')
            if (pageset > 1) {
                setPagin(1)
                _DATA.jump(1);
               }
        }
    }

    return (
        <>
            <h3 className='text-center mt-4'>Members</h3>
            <br />
            <div className="stage text-center pt-5 pb-2">
                {Loaded && Arr.length > 0 && (
                    <Card className={"text-left " + (width > 700 ? 'ml-3 mr-3' : 'ml-2 mr-2')}>
                        <TextField label="Search Member" value={search} className="m-3" onChange={(e) => SearchEng(e.target.value)} />
                        <TextField
                            select
                            label="Choose Group"
                            value={seGroup || '-'}
                            className="m-3"
                            onChange={(e) => handleChangeGroup(e)}
                        >
                            {vPack.drop.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {Filter.length > 0 && seGroup != '-' && (
                            <TextField
                                select
                                label="Choose Filter type"
                                value={seFill || '-'}
                                className="m-3"
                                onChange={(e) => setFr(e.target.value)}
                            >
                                {Filter.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        
                        <ButtonGroup>
                            {seGroup != '-' && seFill != '-' && (
                                <Button className='ml-5 mt-4 mb-3' color="primary" onClick={() => onSearch()} variant="contained">Search</Button>
                            )}
                            <Button className={(seGroup != '-' && seFill != '-' ? 'ml-3' : 'ml-5') + ' mt-4 mb-3 mr-2'} color="secondary" onClick={() => onReset()} variant="contained">Reset</Button>
                        </ButtonGroup>
                    </Card>
                )}
                <Zoom in={mem.length > 0 ? Loaded : false}>
                    <Card className='mt-2 ml-5 mr-5'>
                        <CardContent>
                            Found {seGrad == 2 ? mem.filter(x => x.graduated == true).length : seGrad == 1 ? mem.filter(x => x.graduated == false).length : mem.length} matched BNK48 members
                        </CardContent>
                    </Card>
                </Zoom>
                <Grid item xs={12}>
                    {Loaded ? (
                        <Grid container className='mt-3 justify-content-center' spacing={0}>
                            {
                                mem.length > PER_PAGE && (
                                    <div className='col-md-12 d-flex justify-content-center mb-3'>
                                        <Pagination
                                            count={count}
                                            size="large"
                                            page={pageset}
                                            className='draktextpaging'
                                            onChange={handleChange}
                                        />
                                    </div>
                                )
                            }
                            {mem.length > 0 ? _DATA.currentData().map((item, i) => (seGrad == 2 ? item.graduated == true : seGrad == 1 ? item.graduated == false : item.graduated != undefined) && (
                                <Grid item md={3} data-aos="zoom-in" data-aos-duration="1000">
                                    <div className='mb-5 p-1' onClick={() => ChangeRoute(item.name)}>
                                        <Card>
                                            <CardActionArea>
                                                <CardMedia
                                                    src={item.img}
                                                    component="img"
                                                    className={item.graduated == true ? 'grayimg' : ''}
                                                />
                                                <CardContent>
                                                    <h5>{item.name}</h5>
                                                    {item.shihainin != undefined && (
                                                        <p class="badge text-light" style={{ backgroundColor: "#BF953F" }}>BNK48 Manager (Shihainin)</p>
                                                    )}
                                                    <br />
                                                    {item.headcaptain != undefined && (
                                                        <p class="badge badge-pill badge-primary">BNK48 Captain</p>
                                                    )}
                                                    <br />
                                                    {item.captain != undefined && (
                                                        <p class="badge badge-pill badge-info">BNK48 {item.captain}</p>
                                                    )}
                                                    <br />
                                                    {item.graduated == true && (
                                                        <p class="badge badge-pill badge-warning">Graduating Announced</p>
                                                    )}
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </div>
                                </Grid>

                            )) : (
                                <div className='text-center col-md-12'>
                                    <h6>No BNK48 members to show. Please try different keyword</h6>
                                </div>
                            )}
                            {
                                mem.length > PER_PAGE && (
                                    <div className='col-md-12 d-flex justify-content-center mb-3'>
                                        <Pagination
                                            count={count}
                                            size="large"
                                            page={pageset}
                                            className='draktextpaging'
                                            onChange={handleChange}
                                        />
                                    </div>
                                )
                            }
                        </Grid>
                    ) : (
                        <Grid container className='mt-3 justify-content-center' spacing={2}>
                            <Grid item md={3} xs={12} data-aos="fade-in" data-aos-duration="200">
                                <div className='mb-5 p-1'>
                                    <Card>
                                        <CardActionArea>
                                            <Skeleton variant="rect" height={300} />
                                            <CardContent>
                                                <h5><Skeleton variant="text" /></h5>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12} data-aos="fade-in" data-aos-duration="200">
                                <div className='mb-5 p-1'>
                                    <Card>
                                        <CardActionArea>
                                            <Skeleton variant="rect" height={300} />
                                            <CardContent>
                                                <h5><Skeleton variant="text" /></h5>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12} data-aos="fade-in" data-aos-duration="200">
                                <div className='mb-5 p-1'>
                                    <Card>
                                        <CardActionArea>
                                            <Skeleton variant="rect" height={300} />
                                            <CardContent>
                                                <h5><Skeleton variant="text" /></h5>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </Grid>
                            <Grid item md={3} xs={12} data-aos="fade-in" data-aos-duration="200">
                                <div className='mb-5 p-1'>
                                    <Card>
                                        <CardActionArea>
                                            <Skeleton variant="rect" height={300} />
                                            <CardContent>
                                                <h5><Skeleton variant="text" /></h5>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </div>
        </>
    );
}

export default Memberlist;
